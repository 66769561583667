import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

// Pages :
import Home from "./pages/Home";
import Footer from "./components/footer";

// CSS :
import "./App.scss";
import "./styles/main.scss";
import Services from "./pages/Services";
import Navbar from "./components/navbar";
import Contact from "./components/Contact";
import About from "./pages/About";
import Jobs from "./pages/Jobs";
import JobDetails from "./pages/JobDetails";
import Testimonials from "./pages/Testimonials";
import { useDispatch } from "react-redux";
import ApplyNow from "./pages/ApplyNow";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useDispatch();
  const [country, setCountry] = useState();

  useEffect(() => {
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        if (data.country) {
          setCountry(data.country.trim());
        }
      })
      .catch((error) => console.error("Error:", error));
  }, [dispatch]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Navbar className="postion" country={country} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/jobs/*" element={<JobDetails />} />
        <Route path="/apply-now/*" element={<ApplyNow />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Footer country={country} />
    </>
  );
}

export default App;
