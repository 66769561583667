import React, { useEffect } from "react";

// react-icons
// import { LuCalendarDays } from "react-icons/lu";
// import { FaRegNewspaper } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../services/auth.service";

const Events = () => {
  const dispatch = useDispatch();
  const { allEvents } = useSelector((state) => state.auth);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    AuthService.GetAllEvents(dispatch);
  }, [dispatch]);
  const newsData = [
    {
      id: 0,
      newsTitle:
        "1-Day Workshop on 'String the Spring' on April 29,2019 from 0930 to 0400 hrs at First Floor, SSBC",
      date: "Monday, April 29, 2019",
      img: "Img1",
    },

    {
      id: 1,
      newsTitle:
        "Seminar on 'Air Quality in Pakistan: Interpreting Data and Improving Public Health Communication'",
      date: "Monday, April 22, 2019",
      img: "Img1",
    },
    {
      id: 2,
      newsTitle:
        "A seminar on Distributivity in Algebra, Geometry and Topology",
      date: "Thursday, April 11, 2019",
      img: "Img1",
    },
    {
      id: 3,
      newsTitle:
        "1-Day Workshop on 'String the Spring' on April 29,2019 from 0930 to 0400 hrs at First Floor, SSBC",
      date: "Monday, April 29, 2019",
      img: "Img1",
    },
  ];

  console.log("--------->", allEvents);
  const eventData = [
    {
      id: 0,
      eventTitle:
        "Seminar on 'Air Quality in Pakistan: Interpreting Data and Improving Public Health Communication",
      date: "Monday, April 22, 2019",
      img: "img2",
    },
    {
      id: 1,
      eventTitle:
        "ATKINS CEO Grant Porter Says News Saudi Office to Support Vision 2030",
      date: "Wednesday, March 6, 2019",
      img: "img2",
    },
    {
      id: 2,
      eventTitle:
        "Sheikh Mohammad Says UAE to Start Receiving 10 year Visa Applications",
      date: "Friday, March 1, 2019",
      img: "img2",
    },
    {
      id: 3,
      eventTitle:
        "Seminar on 'Air Quality in Pakistan: Interpreting Data and Improving Public Health Communication",
      date: "Monday, April 22, 2019",
      img: "img2",
    },
  ];

  return (
    <div className="container">
      <div className="flex-events-box">
        <div className="heading">
          <div className="flex">
            <h4>Coming Up </h4>
            <div className="border"></div>
          </div>
          <h2>Events & News </h2>
        </div>

        <div className="flex-events">
          {allEvents &&
            allEvents.map((data) => (
              <div className="event">
                <div className="image">
                  <img src={data.image.Location} alt="" />
                </div>
                <div className="event-details">
                  <div className="flex-content">
                    <h2>{data.title}</h2>
                    <span>{data.date}</span>
                  </div>
                  <div className="details">{data.detail}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Events;
