import React, { useEffect, useState } from "react";
import { MdLocationPin } from "react-icons/md";
import { MdLocalPhone } from "react-icons/md";
import { HiMail } from "react-icons/hi";

import { RiTwitterFill } from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";
import { FaGlobeAmericas } from "react-icons/fa";
import { ImYoutube } from "react-icons/im";
import { FaYelp } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { GrGoogle } from "react-icons/gr";
import Slide from "react-reveal/Slide";

import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const Footer = ({ country }) => {
  // const dispatch = useDispatch();
  // const [country, setCountry] = useState();

  // useEffect(() => {
  //   fetch("https://ipinfo.io/json")
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.country) {
  //         setCountry(data.country.trim());
  //       }
  //     })
  //     .catch((error) => console.error("Error:", error));
  // }, [dispatch]);

  return (
    <div className="footer-container">
      <div className="container">
        <div className="flex-footer">
          <Slide left>
            <div className="flex-contact">
              <div className="heading">
                <h4>Contact</h4>
                <div className="border"></div>
              </div>
              <div className="info">
                {country === "PK" ? (
                  <a
                    href="https://maps.app.goo.gl/9oJs9zfVz2BbYNpm8"
                    target="_blank"
                    rel="noreferrer">
                    <div>
                      <MdLocationPin target="_blank" className="icon" />
                    </div>
                    <p>Rawalpindi, Pakistan</p>
                  </a>
                ) : (
                  <a
                    href="https://maps.app.goo.gl/tyHVfoD5xeZcCDd26"
                    target="_blank"
                    rel="noreferrer">
                    <div>
                      <MdLocationPin target="_blank" className="icon" />
                    </div>
                    <p>United Arab Emirates</p>
                  </a>
                )}

                {country === "PK" ? (
                  <a href="tel:+92 312 3333543">
                    <MdLocalPhone className="icon" />
                    <p>+92 312 3333543</p>
                  </a>
                ) : (
                  <a href="+971 55 897 9031">
                    <MdLocalPhone className="icon" />
                    <p>+971 55 897 9031</p>
                  </a>
                )}
                <a href="mailto:qualityoep@gmail.com">
                  <HiMail className="icon" />
                  <p>qualityoep@gmail.com</p>
                </a>
              </div>
              <div className="icons">
                <a className="circle">
                  <RiTwitterFill className="icon" />
                </a>
                <a className="circle">
                  <FaFacebookF className="icon" />
                </a>
                <a className="circle">
                  <ImYoutube className="icon" />
                </a>
                <a className="circle">
                  <RiInstagramFill className="icon" />
                </a>
                <a className="circle">
                  <GrGoogle className="icon" />
                </a>
              </div>
            </div>
          </Slide>
          <Slide bottom>
            <div className="links">
              <div className="heading">
                <h4>Related Links</h4>
                <div className="border"></div>
              </div>
              <div className="scroll-menu">
                <Link to="/about">
                  <FaAngleRight className="icon" />
                  <p>About</p>
                </Link>
                <Link to="/services">
                  <FaAngleRight className="icon" />
                  <p>Services</p>
                </Link>
                <Link to="/testimonials">
                  <FaAngleRight className="icon" />
                  <p>Testimonials</p>
                </Link>
                <Link to="/jobs">
                  <FaAngleRight className="icon" />
                  <p>Browse Jobs</p>
                </Link>
                <Link to="/contact-us">
                  <FaAngleRight className="icon" />
                  <p>Contact Us</p>
                </Link>
              </div>
            </div>
          </Slide>
          <Slide right>
            <div className="location">
              {country === "PK" ? (
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14783.195487786134!2d73.08392184292363!3d33.65420794211942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfeacacb110075%3A0xa1ee6f743fa00d13!2sGulistan-e-Jinnah%20Colony%20Faizabad%2C%20Rawalpindi%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1727801307448!5m2!1sen!2s"
                  loading="lazy"
                  allowfullscreen=""
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
              ) : (
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d115535.42499886005!2d55.1082802!3d25.1869618!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f690015f84c59%3A0x7dd5a3ca7fa96559!2sQuality%20Recruiter%20Dubai!5e0!3m2!1sen!2s!4v1726747043938!5m2!1sen!2s"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"></iframe>
              )}
            </div>
          </Slide>
        </div>
        <div className="border"></div>
        <div className="copyright">
          <p>
            © Copyright 2024 | Powered by{" "}
            <b>
              <Link to="/">QR</Link>
            </b>
            . All Right Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
