import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slide } from "react-reveal";
import {
  TextField,
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Input,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import { toast } from "react-toastify";

const ApplyNow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const id = location?.state?.id;

  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    country: "",
    city: "",
    gender: "",
    location: "",
    file: null,
    jobId: id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleFileUpload = (e) => {
    setFormValues({
      ...formValues,
      file: e.target.files[0],
    });
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("firstName", formValues.firstName);
    formData.append("lastName", formValues.lastName);
    formData.append("email", formValues.email);
    formData.append("phoneNo", formValues.phoneNo);
    formData.append("country", formValues.country);
    formData.append("city", formValues.city);
    formData.append("gender", formValues.gender);
    formData.append("location", formValues.location);
    formData.append("file", formValues.file);
    formData.append("jobId", formValues.jobId);

    let [success, error] = await AuthService.ApplyForJob(formData, dispatch);

    if (success) {
      navigate("/jobs");
      setFormValues({
        firstName: "",
        lastName: "",
        email: "",
        phoneNo: "",
        country: "",
        city: "",
        gender: "",
        location: "",
        file: null,
        jobId: id,
      });
    }
  };
  return (
    <div className="container spacing">
      <div className="jobs-container">
        <div className="heading-container">
          <Slide bottom>
            <div className="heading">
              <div className="flex">
                <h4>Apply Now</h4>
                <div className="border"></div>
              </div>
              <h2>Fill all fields</h2>
            </div>
          </Slide>
        </div>
        <div className="flex-fields">
          <Slide bottom>
            <div className="flex-inputs">
              <TextField
                className="input"
                color="warning"
                label="First Name"
                variant="filled"
                name="firstName"
                value={formValues.firstName}
                onChange={handleChange}
              />
              <TextField
                className="input"
                color="warning"
                label="Last Name"
                variant="filled"
                name="lastName"
                value={formValues.lastName}
                onChange={handleChange}
              />
            </div>
          </Slide>
          <Slide bottom>
            <div className="flex-inputs">
              <TextField
                className="input"
                color="warning"
                label="Your Email"
                variant="filled"
                name="email"
                value={formValues.email}
                onChange={handleChange}
                inputProps={{
                  type: "email",
                }}
              />
              <TextField
                className="input"
                color="warning"
                label="Phone No."
                variant="filled"
                name="phoneNo"
                value={formValues.phoneNo}
                onChange={handleChange}
              />
            </div>
          </Slide>
          <Slide bottom>
            <div className="flex-inputs">
              <TextField
                className="input"
                color="warning"
                label="Country"
                variant="filled"
                name="country"
                value={formValues.country}
                onChange={handleChange}
              />
              <TextField
                className="input"
                color="warning"
                label="City"
                variant="filled"
                name="city"
                value={formValues.city}
                onChange={handleChange}
              />
            </div>
          </Slide>
          <Slide bottom>
            <div className="flex-inputs">
              <FormControl variant="filled" className="input">
                <InputLabel color="warning">Gender</InputLabel>
                <Select
                  color="warning"
                  name="gender"
                  value={formValues.gender}
                  onChange={handleChange}>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
              <label htmlFor="upload-cv" className="input">
                <Input
                  style={{
                    width: "100%",
                    padding: "20px 0 5px",
                    background: "#F0F0F0",
                  }}
                  accept="application/pdf,application/msword,.docx"
                  id="upload-cv"
                  type="file"
                  name="file"
                  onChange={handleFileUpload}
                />
              </label>
            </div>
          </Slide>
          <Slide bottom>
            <div className="flex-inputs">
              <TextField
                className="input"
                color="warning"
                label="Address"
                variant="filled"
                multiline
                rows={4}
                name="location"
                value={formValues.location}
                onChange={handleChange}
              />
            </div>
          </Slide>
          <Slide bottom>
            <div className="flex-inputs">
              <Button
                className="button"
                variant="contained"
                onClick={handleSubmit}>
                Send Message
              </Button>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default ApplyNow;
