import React, { useEffect } from "react";
import ModalVideo from "react-modal-video";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { AiOutlineCaretRight } from "react-icons/ai";
import Recaptcha from "react-google-recaptcha";
import Slide from "react-reveal/Slide";
import AuthService from "../services/auth.service";
import { useDispatch, useSelector } from "react-redux";

const Contact = () => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { loading } = useSelector((state) => state.auth);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phoneNo: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    AuthService.AddContact(dispatch, formValues, setFormValues);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-container" id="contact">
      <Slide bottom>
        <div className="heading">
          <div className="flex">
            <h4>Keep in Touch</h4>
            <div className="border"></div>
          </div>
          <h2>Contact Us</h2>
        </div>
      </Slide>
      <div className="flex-contact">
        <div className="video-section">
          <React.Fragment>
            {/* <ModalVideo
              channel="youtube"
              autoplay
              isOpen={isOpen}
              videoId="L61p2uyiMSo"
              onClose={() => setOpen(false)}
            /> */}
            <div className="startContainer">
              <button className="button pulseBox" onClick={() => setOpen(true)}>
                <AiOutlineCaretRight className="icon" />{" "}
              </button>
            </div>
          </React.Fragment>
        </div>
        <Slide bottom>
          <div className="contact-form">
            <Slide bottom>
              <div className="headings">
                <div className="flex">
                  <h4>Reservation</h4>
                  <div className="border"></div>
                </div>
                <h2>Keep in Touch</h2>
              </div>
            </Slide>
            <div className="flex-fields">
              <Slide bottom>
                <div className="flex-inputs">
                  <TextField
                    className="input"
                    color="warning"
                    label="Your Name"
                    variant="filled"
                    name="name"
                    value={formValues.name}
                    onChange={handleChange}
                  />
                  <TextField
                    className="input"
                    color="warning"
                    label="Your Email"
                    variant="filled"
                    name="email"
                    value={formValues.email}
                    onChange={handleChange}
                    inputProps={{
                      type: "email",
                    }}
                  />
                </div>
              </Slide>
              <Slide bottom>
                <div className="flex-inputs">
                  <TextField
                    className="input"
                    color="warning"
                    label="Phone No."
                    variant="filled"
                    name="phoneNo"
                    value={formValues.phoneNo}
                    onChange={handleChange}
                  />
                  <TextField
                    className="input"
                    color="warning"
                    label="Subject"
                    variant="filled"
                    name="subject"
                    value={formValues.subject}
                    onChange={handleChange}
                  />
                </div>
              </Slide>
              <Slide bottom>
                <div className="flex-inputs">
                  <TextField
                    className="input"
                    color="warning"
                    label="Message"
                    variant="filled"
                    multiline
                    rows={4}
                    name="message"
                    value={formValues.message}
                    onChange={handleChange}
                  />
                </div>
              </Slide>
              <Slide bottom>
                <div className="flex-inputs">
                  <Button
                    loading={loading}
                    className="button"
                    variant="contained"
                    disableElevation
                    onClick={handleSubmit}>
                    Send Message
                  </Button>
                </div>
              </Slide>
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default Contact;
