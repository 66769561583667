import React, { useEffect, useState } from "react";

//CSS
import "../styles/testimonials.scss";

// Images
import TestimonialsImg1 from "../assets/testimonialsImg1.jpg";
import TestimonialsImg2 from "../assets/testimonialsImg2.jpg";
import TestimonialsImg3 from "../assets/testimonialsImg3.jpg";
import TestimonialsImg4 from "../assets/testimonialsImg4.jpg";
import TestimonialsImg5 from "../assets/testimonialsImg5.jpg";
import TestimonialsImg6 from "../assets/testimonialsImg6.jpg";
import TestimonialsImg7 from "../assets/testimonialsImg7.jpg";

const Testimonials = () => {
  const [file, setFile] = useState(null);
  const testimonialImages = [
    { id: 0, TestimonialImg: TestimonialsImg1 },
    { id: 1, TestimonialImg: TestimonialsImg2 },
    { id: 2, TestimonialImg: TestimonialsImg3 },
    { id: 3, TestimonialImg: TestimonialsImg4 },
    { id: 4, TestimonialImg: TestimonialsImg5 },
    { id: 5, TestimonialImg: TestimonialsImg6 },
    { id: 6, TestimonialImg: TestimonialsImg7 },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container">
      <div className="testimonial-container">
        <h1>Testimonials</h1>
        <div className="testimonial-card">
          {testimonialImages.map((img) => {
            return (
              <div
                className="testimonial-img"
                key={img.id}
                onClick={() => setFile(img)}>
                <img
                  src={img.TestimonialImg}
                  alt="ERROR"
                  className="testimonial-image"
                />
              </div>
            );
          })}
        </div>

        {file && (
          <div className="popup-media">
            <div>
              <span onClick={() => setFile(null)}>&times;</span>
              {file && <img src={file?.TestimonialImg} alt="ERROR" />}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
