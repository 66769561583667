import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slide, Zoom } from "react-reveal";
import Image from "../assets/Zubair.jpeg";
import { Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";

const JobDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { getJobDetails } = useSelector((state) => state.auth);

  useEffect(() => {
    const id = location?.state?.id;
    AuthService.GetJobById(dispatch, id);
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };
  return (
    <div className="container spacing">
      <div className="jobs-container">
        <div className="heading-container">
          <Slide bottom>
            <div className="heading">
              <div className="flex">
                <h4>View</h4>
                <div className="border"></div>
              </div>
              <h2>Job Details</h2>
            </div>
          </Slide>
        </div>
        <div className="job-detail-container " id="about">
          <div className="image">
            <Zoom>
              <img src={getJobDetails?.image?.Location || ""} alt="ERROR" />
            </Zoom>
          </div>
          <div className="about-box">
            <div className="para">
              <div className="job-title">
                Job Title : &nbsp; &nbsp;
                <strong style={{ color: "var(--primary)" }}>
                  {getJobDetails?.title || ""}
                </strong>
              </div>
              <div className="job-title">
                Country : &nbsp; &nbsp;
                <strong style={{ color: "var(--primary)" }}>
                  {getJobDetails?.country || ""}
                </strong>
              </div>
              <div className="job-title">
                Location : &nbsp; &nbsp;
                <strong style={{ color: "var(--primary)" }}>
                  {getJobDetails?.location || ""}
                </strong>
              </div>
              <div className="job-title">
                Created Date : &nbsp; &nbsp;
                <strong style={{ color: "var(--primary)" }}>
                  {formatDate(getJobDetails?.createdAt)}
                </strong>
              </div>
              <div className="job-title">Description : &nbsp; &nbsp;</div>
              <p style={{ minHeight: "100px" }}>
                {getJobDetails?.detail || ""}
              </p>
              <Button
                className="action-button"
                variant="contained"
                disableElevation
                size="large"
                style={{ marginTop: "5px" }}
                onClick={() => {
                  navigate(`/apply-now/${getJobDetails?._id}`, {
                    state: { id: getJobDetails?._id },
                  });
                }}>
                Apply Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
