import React, { useEffect } from "react";

import Image1 from "../assets/security-gard.png";
import Image2 from "../assets/education.png";
import Image3 from "../assets/faculty.png";
import Image4 from "../assets/second.jpg";
import Image5 from "../assets/oil.png";
import Image6 from "../assets/aviation.png";
import Image7 from "../assets/retail.png";
import Image8 from "../assets/health.png";

// Helpers :
import Roll from "react-reveal/Roll";
import OurClients from "../components/OurClients";

const Services = () => {
  const services = [
    {
      image: Image1,
      heading: "Security",
      para: "We offer top-notch recruitment services for the security industry, connecting you with qualified professionals for your needs.",
    },
    {
      image: Image2,
      heading: "Education",
      para: "We  offering expert recruitment services for the education sector, connecting top talent with leading to the institutions for successful careers.",
    },
    {
      image: Image3,
      heading: "Faculty Management",
      para: "We are Providing expert faculty management solutions to the recruit and retain top educators for schools, collages, universities, and educational institutions.",
    },
    {
      image: Image4,
      heading: "Construction",
      para: "Our Construction recruitment service connects the skilled professionals with best top-tier companies, ensuring quality hires for every project and role",
    },
    {
      image: Image5,
      heading: "OIL & GAS",
      para: "We Offering expert recruitment services in the Oil & Gas sector, connecting top talent with leading companies for optimal workforce solutions.",
    },
    {
      image: Image6,
      heading: "Aviation",
      para: "Offering specialized recruitment services for the aviation industry, connecting top talent with leading companies for optimal success.",
    },
    {
      image: Image7,
      heading: "FMCG & Retail",
      para: "Quality Recruiter offering the specialized  services for the FMCG and Retail sectors, connecting top talent with leading brands!",
    },
    {
      image: Image8,
      heading: "Health Care",
      para: "We offer top-notch recruitment services for the Health Care sector, connecting skilled professionals with leading organizations.",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="container spacing">
        <div className="services-container">
          <div className="heading">
            <div className="flex" style={{ textAlign: "center" }}>
              <h4>What We Are?</h4>
              <div className="border"></div>
            </div>
            <h2>Services We Offer</h2>
          </div>
          <div className="flex-cards">
            {services.map((data, i) => {
              return (
                <Roll left key={i}>
                  <div className="card">
                    <div className="back_drop"></div>
                    <div className="logo">
                      <img src={data.image} alt="" />
                    </div>
                    <div className="heading">
                      <h5>{data.heading}</h5>
                    </div>
                    <div className="para">
                      <p>{data.para}</p>
                    </div>
                  </div>
                </Roll>
              );
            })}
          </div>
        </div>
      </div>
      <OurClients />
    </>
  );
};

export default Services;
