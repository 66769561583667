import React, { useEffect } from "react";

// Components :

// helpers :
import Bounce from "react-reveal/Bounce";
import OurMission from "../components/OurMission";
import Contact from "../components/Contact";
import OurClients from "../components/OurClients";
import Events from "../components/Events";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="home-container">
        <div className="container" id="home">
          <div className="fts-group">
            <Bounce left>
              <h1>Quality Human Resources Consultancy</h1>
              <h3>Talent Solution for Tomorrow</h3>
              <div className="primary-button">
                <a href="tel:+92 312 3333543">Call Us</a>
              </div>
            </Bounce>
          </div>
        </div>
      </div>
      <OurMission />
      <OurClients />
      <Events />
    </>
  );
};

export default Home;
