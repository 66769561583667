import React, { useEffect } from "react";
import Zoom from "react-reveal/Zoom";
import Image from "../assets/Zubair.jpeg";
import License from "../assets/licence.png";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="container spacing">
      <div className="about-container " id="about">
        <div className="image">
          <Zoom>
            <img src={Image} alt="" />
          </Zoom>
        </div>
        <div className="about-box">
          <div className="heading">
            <div className="flex">
              <h4>Our CEO</h4>
              <div className="border"></div>
            </div>
            <h2>CEO's Message</h2>
          </div>
          <div className="para">
            <p>
              Dear Valued Clients and Partners, I am delighted to connect with
              you as the CEO of Quality Recruiter Since our journey began in
              2014, we have been dedicated to bridging the gap between
              exceptional talent and outstanding opportunities, creating
              pathways to success for both our candidates and clients.
            </p>
            <p>
              Over the past decade, we have grown and evolved, driven by our
              commitment to excellence and our passion for people. Our mission
              remains steadfast: to identify and match the right talent with the
              right roles, fostering growth and excellence in every placement we
              make.
            </p>
            <p>
              Whether you are seeking top-tier talent to enhance your
              organization or looking for the next step in your career, we are
              here to support you every step of the way. Thank you for choosing
              Quality Recruiter as your trusted recruitment partner.
            </p>
            <p>Together, let's unlock potential and achieve greatness</p>
          </div>
        </div>
      </div>
      <div
        className="container spacing"
        id="services"
        style={{ marginBottom: "60px" }}>
        <div className="flex-services">
          <h2>Our Mission</h2>
          <p>
            At Quality Recruitment, our mission is to connect exceptional talent
            with organizations that drive industries forward. We are dedicated
            to offering tailored recruitment solutions across a wide range of
            sectors, including Hospitality, Oil & Gas, Aviation, Retail,
            Security, Health Care, Education, and more. By carefully
            understanding the unique needs of both businesses and candidates, we
            ensure that each placement fosters success and growth. We believe in
            building partnerships based on trust, transparency, and
            collaboration, enabling us to deliver unmatched quality and
            satisfaction to all our clients and job seekers.
          </p>
          <p>
            Our commitment to excellence goes beyond simply filling roles. We
            strive to create long-lasting, positive impacts for the
            organizations we support and the professionals we represent. Our
            focus is on aligning the right talent with the right opportunities,
            empowering companies to thrive and individuals to achieve their
            career ambitions. Through this holistic approach, we aim to
            contribute to the success of industries around the globe, adapting
            and evolving as the demands of the modern workforce continue to
            change.
          </p>
        </div>
      </div>
      <div className="license">
        <h2>Our Licence</h2>
        <img src={License} alt="" />
      </div>
    </div>
  );
};

export default About;
