import React from "react";
import first from "../assets/first.png";
import second from "../assets/second.jpg";
import third from "../assets/third.jpg";
import forth from "../assets/forth.jpg";
import Zoom from "react-reveal/Zoom";

const OurMission = () => {
  return (
    <>
      <div className="container spacing" id="about">
        <div className="flex-about">
          <div className="images-box">
            <div className="upper">
              <Zoom>
                <div className="large-image">
                  <img src={first} alt="" />
                </div>
              </Zoom>
              <Zoom>
                <div className="small-image">
                  <img src={second} alt="" />
                </div>
              </Zoom>
            </div>
            <div className="lower">
              <Zoom>
                <div className="small-image">
                  <img src={third} alt="" />
                </div>
              </Zoom>
              <Zoom>
                <div className="large-image">
                  <img src={forth} alt="" />
                </div>
              </Zoom>
            </div>
          </div>
          <div className="about-box">
            <div className="heading">
              <div className="flex">
                <h4>Introduction</h4>
                <div className="border"></div>
              </div>
              <h2>Quality Recruiter</h2>
            </div>
            <div className="para">
              <p>
                We are excited to introduce Quality Recruiter, a proud member of
                the global talent solutions network. Since our establishment in
                2014, we have specialized in providing recruitment services to
                the construction industry in the UAE, working with leading
                contractors across the region. Over the years, our expertise has
                expanded across the Middle East, Europe, and beyond, allowing us
                to grow our reach and diversify our offerings. Our commitment to
                building strong relationships with clients has been the
                cornerstone of our success, ensuring we deliver exceptional
                talent and meet the unique needs of every partner we work with.
              </p>
              <p>
                Today, Quality Recruitment proudly serves a variety of sectors,
                including Hospitality, Oil & Gas, Aviation, Retail, Security,
                Health Care, Education, Construction, Post & Logistics, and
                facilities management. By leveraging our global network and
                industry expertise, we provide customized recruitment solutions
                that align with both the short-term goals and long-term visions
                of our clients. Our success is deeply rooted in collaboration,
                trust, and the ability to consistently connect the right talent
                with the right opportunities, driving sustainable growth and
                success for businesses and professionals alike.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container spacing"
        id="services"
        style={{ marginBottom: "60px" }}>
        <div className="flex-services">
          <h2>Our Mission</h2>
          <p>
            At Quality Recruitment, our mission is to connect exceptional talent
            with organizations that drive industries forward. We are dedicated
            to offering tailored recruitment solutions across a wide range of
            sectors, including Hospitality, Oil & Gas, Aviation, Retail,
            Security, Health Care, Education, and more. By carefully
            understanding the unique needs of both businesses and candidates, we
            ensure that each placement fosters success and growth. We believe in
            building partnerships based on trust, transparency, and
            collaboration, enabling us to deliver unmatched quality and
            satisfaction to all our clients and job seekers.
          </p>
          <p>
            Our commitment to excellence goes beyond simply filling roles. We
            strive to create long-lasting, positive impacts for the
            organizations we support and the professionals we represent. Our
            focus is on aligning the right talent with the right opportunities,
            empowering companies to thrive and individuals to achieve their
            career ambitions. Through this holistic approach, we aim to
            contribute to the success of industries around the globe, adapting
            and evolving as the demands of the modern workforce continue to
            change.
          </p>
        </div>
      </div>
    </>
  );
};

export default OurMission;
