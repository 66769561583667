import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  allJobs: [],
  allEvents: [],
  getJobDetails: {},
  loading: false,
  settings: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => {},
    setUser: (state, action) => {
      state.user = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setSettings: (state, action) => {
      state.settings = action.payload;
    },
    setAllJobs: (state, action) => {
      state.allJobs = action.payload;
    },
    setAllEvents: (state, action) => {
      state.allEvents = action.payload;
    },
    setGetJobDetails: (state, action) => {
      state.getJobDetails = action.payload;
    },
    removeJobs: (state, action) => {
      const id = action.payload;
      state.allJobs = state.allJobs.filter(({ _id }) => _id !== id);
    },
  },
});

const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;
export default authReducer;
