import React from "react";
import DLusco from "../assets/dlusco.png";
import DPWorld from "../assets/dp-world.png";
import DubaiWorld from "../assets/dubai-world.png";
import Innovation from "../assets/innovation.png";
import Security from "../assets/security.png";
import WeOne from "../assets/we-one.png";
import AlBawani from "../assets/al-bawani.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const arr = [
  {
    img: DLusco,
    heading: "Lorem Ipsum",
  },
  {
    img: DPWorld,
    heading: "Lorem Ipsum",
  },
  {
    img: DubaiWorld,
    heading: "Lorem Ipsum",
  },
  {
    img: Innovation,
    heading: "Lorem Ipsum",
  },
  {
    img: Security,
    heading: "Lorem Ipsum",
  },
  {
    img: WeOne,
    heading: "Lorem Ipsum",
  },
  {
    img: AlBawani,
    heading: "Lorem Ipsum",
  },
];

const OurClients = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1270,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <div className="clients-container">
      <div className="container">
        <div className="flex-clients">
          <div className="heading">
            <div className="flex">
              <h4>Who are our clients</h4>
              <div className="border"></div>
            </div>
            <h2>Our Clients </h2>
          </div>
          <div className="parent">
            <Slider {...settings}>
              {arr.map((data, i) => {
                return (
                  <div className="slide_parent" key={i}>
                    <img src={data.img} alt="INTERNET ERROR" />
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurClients;
