import http from "./http.service";
import { toast } from "react-toastify";
import Promisable from "./promisable.service";
import { authActions } from "./../redux/slices/loginSlice";
import { Alert } from "@mui/material";

const AuthService = {
  // login: async (data, dispatch, navigate) => {
  //   dispatch?.(authActions.setLoading(true));
  //   const [success, error] = await Promisable.asPromise(
  //     http.post(`api/auth/login`, data)
  //   );

  //   if (success) {
  //     const data = success.data.result;
  //     localStorage.setItem("token", data.token);
  //     localStorage.setItem("user", JSON.stringify(data));
  //     dispatch?.(authActions.setUser(data));

  //     dispatch?.(authActions.setLoading(false));
  //     toast.success("Login Successfully");
  //     navigate("/dashboard");
  //   }

  //   if (error) {
  //     toast.error("Something went wrong");
  //   }

  //   dispatch?.(authActions.setLoading(false));
  //   return [success, error];
  // },
  ApplyForJob: async (data, dispatch) => {
    dispatch?.(authActions.setLoading(true));
    const [success, error] = await Promisable.asPromise(
      http.post(`api/apply/add`, data)
    );

    if (success) {
      toast.success("Applied Successfully!");
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  AddContact: async (dispatch, data, setFormValues) => {
    dispatch?.(authActions.setLoading(true));
    const [success, error] = await Promisable.asPromise(
      http.post(`api/contactUs/add`, data)
    );

    if (success) {
      // message.success("Thank you for your message. It has been sent");
      setFormValues({
        name: "",
        email: "",
        phoneNo: "",
        subject: "",
        message: "",
      });
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  GetAllJobs: async (dispatch) => {
    dispatch?.(authActions.setLoading(true));
    const [success, error] = await Promisable.asPromise(
      http.get(`api/job/get`)
    );

    if (success) {
      const data = success.data.result;
      dispatch?.(authActions.setAllJobs(data));
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  GetJobById: async (dispatch, id) => {
    dispatch?.(authActions.setLoading(true));
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.get(`api/job/${id}`)
    );

    if (success) {
      const data = success.data.result;
      dispatch?.(authActions.setGetJobDetails(data));
      dispatch?.(authActions.setLoading(false));
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  GetAllEvents: async (dispatch) => {
    dispatch?.(authActions.setLoading(true));
    const [success, error] = await Promisable.asPromise(
      http.get(`api/event/get`)
    );

    if (success) {
      const data = success.data.result;
      dispatch?.(authActions.setAllEvents(data));
    }

    if (error) {
      toast.error("Something went wrong");
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  logout: () => {
    localStorage.removeItem("token");
  },
};

export default AuthService;
