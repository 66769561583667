import React, { useState } from "react";
import logo from "../assets/white-logo.png";

// ****** Mui Hameburger ********
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import Fade from "react-reveal/Fade";
import { Link, NavLink } from "react-router-dom";

const Navbar = ({ country }) => {
  const [background, setBackground] = useState(false);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 265 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <div className="main">
        <div className="logo" style={{ textAlign: "center" }}>
          <img src={logo} alt="" style={{ height: "80px", width: "80px" }} />
        </div>
        <div className="border"></div>
        <div className="nav">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About</NavLink>
          <NavLink to="/services">Services</NavLink>
          <NavLink to="/testimonials">Testimonials</NavLink>
          <NavLink to="/jobs">Jobs</NavLink>
          <NavLink to="/contact-us">Contact</NavLink>
          {country === "PK" ? (
            <a href="tel:+92 312 3333543" className="call">
              Call Us
            </a>
          ) : (
            <a href="+971 55 897 9031" className="call">
              Call Us
            </a>
          )}
        </div>
      </div>
    </Box>
  );

  const changeNavBar = () => {
    if (window.scrollY >= 80) {
      setBackground(true);
    } else {
      setBackground(false);
    }
  };
  window.addEventListener("scroll", changeNavBar);

  return (
    <Fade top>
      <div className={background ? "nav-container scroll" : "nav-container"}>
        <Link to="/" className="logo">
          <img src={logo} alt="" />
        </Link>

        <div className="menu">
          <div className="web-menu">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/services">Services</NavLink>
            <NavLink to="/testimonials">Testimonials</NavLink>
            <NavLink to="/jobs">Jobs</NavLink>
            <NavLink to="/contact-us">Contact</NavLink>
            {country === "PK" ? (
              <a href="tel:+92 312 3333543" className="call">
                Call Us
              </a>
            ) : (
              <a href="+971 55 897 9031" className="call">
                Call Us
              </a>
            )}
          </div>
          <div className="mobile-menu">
            <div className="ham_none">
              {["top"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <div className="menu">
                    <MenuIcon
                      onClick={toggleDrawer(anchor, true)}
                      style={{ fontSize: "30px" }}
                    />
                  </div>
                  <SwipeableDrawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    onOpen={toggleDrawer(anchor, true)}>
                    {list(anchor)}
                  </SwipeableDrawer>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Navbar;
