import React, { useEffect } from "react";
import { Slide } from "react-reveal";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../services/auth.service";

const Jobs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allJobs } = useSelector((state) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    AuthService.GetAllJobs(dispatch);
  }, [dispatch]);

  return (
    <div className="container spacing">
      <div className="jobs-container">
        <div className="heading-container">
          <Slide bottom>
            <div className="heading">
              <div className="flex">
                <h4>Jobs currently</h4>
                <div className="border"></div>
              </div>
              <h2>Available</h2>
            </div>
          </Slide>
        </div>
        <div className="flex-cards">
          {allJobs &&
            allJobs.map((job) => (
              <div className="job-card">
                <div className="image">
                  <img src={job?.image?.Location || ""} alt="ERROR" />
                </div>
                <div className="details">
                  <div className="title">{job?.title}</div>
                  <p
                    style={{ height: "35px", fontSize: "13px", color: "grey" }}>
                    {job?.detail.length > 60
                      ? `${job?.detail.slice(0, 70)}...`
                      : job?.detail}
                  </p>
                  <Button
                    className="button"
                    variant="contained"
                    disableElevation
                    style={{ marginTop: "5px" }}
                    onClick={() =>
                      navigate(`/jobs/${job?._id}`, {
                        state: { id: job?._id },
                      })
                    }>
                    View Details
                  </Button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Jobs;
